import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/layouts/MdxLayout.js";
import SEO from "../../components/Seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="FAQ – Archibald James Membership" description="Archibald James membership" mdxType="SEO" />
    <article className="prose mx-auto">
      <h1 {...{
        "id": "faq"
      }}>{`FAQ`}</h1>
      <p>{`This page is under development.`}</p>
      <p>{`Contact bryan at archibaldjames.com with questions. We're happy to help!`}</p>
      <hr></hr>
      <h2 {...{
        "id": "i-need-to-update-my-address--payment-method"
      }}>{`I need to update my address / payment method.`}</h2>
      <p><a parentName="p" {...{
          "href": "/membership/manage"
        }}>{`Manage your details here`}</a></p>
      <h2 {...{
        "id": "how-much-is-shipping"
      }}>{`How much is shipping?`}</h2>
      <p>{`Most of our customers pay $20-25 to deliver to an urban/suburban address. You can put together an order in our shop to see the latest estimate.`}</p>
      <h2 {...{
        "id": "can-i-order-products-in-my-statecounty"
      }}>{`Can I order products in my state/county?`}</h2>
      <p>{`We ship to most jurisdictions, but local laws prevent us from delivering everywhere. Sign up for our `}<a parentName="p" {...{
          "href": "/club"
        }}>{`club`}</a>{` to find out if we can ship to your address.`}</p>
    </article>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      